import React, { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useIsMobile } from '../hooks/useIsMobile'
import { useGet } from '../hooks/useGet'
import { RadBox } from './RadBox'
import { RadButton } from './RadButton'
import { RadCards } from './RadCards'
import { RadHeader } from './RadHeader'
import { RadLink } from './RadLink'
import { RadPagination } from './RadPagination'
import { RadSpaceBetween } from './RadSpaceBetween'
import { RadTable } from './RadTable'
import { RadTextFilter } from './RadTextFilter'
import { RadAppLayout } from './RadAppLayout'
import { RadExpandableSection } from './RadExpandableSection'
import { RadGrid } from './RadGrid'
import { getBreadcrumbName, toTitleCase as titleCase } from './utilities'

export function RadAdaptiveList ({
  entity,
  fields,
  filters = [],
  expandableSections = [],
  emptyText = null,
  onQueryChanged = null
}) {
  const pageLength = 20
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentPageIndex, setCurrentPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const [filteringText, setFilteringText] = useState(searchParams.get('search') ?? '')
  const [searchText, setSearchText] = useState(searchParams.get('search') ?? '')
  const pathParts = [
    `/api/${entity}?`,
    `search=${searchText}`,
    `&limit=${pageLength}`,
    `&offset=${(currentPageIndex - 1) * pageLength}`
  ]

  if (searchText !== '') {
    searchParams.set('search', searchText)
  } else {
    searchParams.delete('search')
  }

  if (filters.length > 0) {
    for (const { name, values } of filters) {
      if (values != null && values.filter(x => x !== '').length > 0) {
        searchParams.set(name, values.join(','))
        pathParts.push(`&${name}=${values.join(',')}`)
      } else {
        searchParams.delete(name)
      }
    }
  }

  useEffect(() => {
    if (onQueryChanged != null) {
      onQueryChanged(searchParams.toString())
    }
  }, [filters, searchText])

  const { data: items, count } = useGet(pathParts.join(''))

  const header = (field) => {
    let value
    if (typeof field === 'string') {
      value = field
    }
    if (typeof field === 'object') {
      value = field.name
    }
    if (value.includes('.')) {
      return value.split('.')[0]
    } else {
      return value
    }
  }

  const content = (item, field) => {
    if (field === 'address') {
      return (
        <>
          <div>{item.address?.line1}</div>
          {item.address?.line2 && <div>{item.address?.line2}</div>}
          <div>{item.address?.city} {item.address?.state} {item.address?.zip}</div>
        </>
      )
    }

    let value
    if (typeof field === 'string') {
      value = field
    }
    if (typeof field === 'object') {
      value = field?.field?.toString() ?? field.name
    }
    if (value.includes('.')) {
      const parts = value.split('.')
      value = parts.reduce((o, i) => (o ? o[i] : null), item)
    } else {
      value = item[value]
    }
    if (field.link === true) {
      return <RadLink fontSize='inherit' href={`/${entity}/${item.id}`}>{value}</RadLink>
    } else {
      return value
    }
  }

  const definitions = () => {
    const results = []
    for (const field of fields) {
      results.push({
        id: header(field),
        header: field.header ?? titleCase(header(field)),
        content: item => content(item, field) ?? '-'
      })
    }
    if (!isMobile) {
      results.push({
        id: 'actions',
        // header: 'Actions',
        content: item => <RadButton variant='inline-icon' iconName='edit' href={`/${entity}/${item.id}/edit?redirectURL=/${entity}`} />
      })
    }
    return results
  }

  const contentHeader = () => {
    return (
      <RadHeader
        variant='h1'
        counter={'(' + count + ')'}
        actions={
          <RadSpaceBetween direction='horizontal' size='xs'>
            <RadButton onClick={() => navigate(`/${entity}/create`)}>Create</RadButton>
          </RadSpaceBetween>
        }
      >
        {getBreadcrumbName(entity)}
      </RadHeader>
    )
  }

  const filter = (
    <RadSpaceBetween size='m'>
      <RadTextFilter
        filteringPlaceholder='Search'
        filteringAriaLabel='Search'
        filteringText={filteringText}
        onChange={({ detail }) => setFilteringText(detail.filteringText)}
        onDelayedChange={({ detail }) => setSearchText(detail.filteringText)}
      />
      {
        filters.length > 0 &&
          <RadExpandableSection
            defaultExpanded={filters.map(f => f.values).flat().filter(x => x != null && x.trim().length > 0).length > 0}
            headerText='Filters'
            headerDescription={`Additional ${entity} filters`}
          >
            <RadGrid gridDefinition={[
              { colspan: { default: 12, m: 6 } },
              { colspan: { default: 12, m: 6 } }
            ]}
            >
              <RadSpaceBetween size='m'>
                {filters.filter((_, i) => i % 2 === 0).map(filter => filter.component)}
              </RadSpaceBetween>
              <RadSpaceBetween size='m'>
                {filters.filter((_, i) => i % 2 !== 0).map(filter => filter.component)}
              </RadSpaceBetween>
            </RadGrid>
          </RadExpandableSection>
    }
      {
      expandableSections.length > 0 &&
          expandableSections.map(({ key, component, ...rest }) => (
            <RadExpandableSection key={key} {...rest}>{component}</RadExpandableSection>
          ))
    }
    </RadSpaceBetween>
  )

  const pagination = (
    <RadPagination
      currentPageIndex={currentPageIndex}
      pagesCount={Math.ceil(count / pageLength)}
      onChange={({ detail }) => {
        searchParams.set('page', detail.currentPageIndex)
        setSearchParams(searchParams)
        setCurrentPageIndex(detail.currentPageIndex)
      }}
      ariaLabels={{
        nextPageLabel: 'Next page',
        previousPageLabel: 'Previous page',
        pageLabel: pageNumber => `Page ${pageNumber} of all pages`
      }}
    />
  )

  const appContent = () => {
    if (isMobile) {
      return (
        <RadCards
          ariaLabels={{
            itemSelectionLabel: (e, t) => `select ${t.name}`,
            selectionGroupLabel: 'Item selection'
          }}
          cardDefinition={{
            header: item => (
              <RadBox fontWeight='bold' fontSize='heading-l' padding={{ bottom: 's' }}>
                {definitions()[0].content(item)}
              </RadBox>
            ),
            sections: definitions().filter((x, i) => i > 0) // .filter(x => x.card === true)
          }}
          cardsPerRow={[
            { cards: 1 },
            { minWidth: 640, cards: 2 },
            { minWidth: 960, cards: 3 },
            { minWidth: 1280, cards: 4 }
          ]}
          items={items}
          filter={filter}
          pagination={pagination}
          variant='full-page'
          empty={empty}
        />
      )
    } else {
      return (
        <RadTable
          variant='full-page'
          items={items}
          columnDefinitions={definitions().map(x => ({ ...x, cell: x.content }))}
          filter={filter}
          pagination={pagination}
          empty={empty}
        />
      )
    }
  }

  const empty = (
    <RadBox textAlign='center' padding={{ top: 'l' }}>
      <RadBox>
        {emptyText ?? 'No matches found.'}
      </RadBox>
    </RadBox>
  )

  if (items != null) {
    return (
      <RadAppLayout
        contentHeader={contentHeader()}
        content={appContent()}
      />
    )
  }
}
