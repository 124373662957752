
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_145pa_1vwzw_141",
  "focus-lock": "awsui_focus-lock_145pa_1vwzw_173",
  "calendar": "awsui_calendar_145pa_1vwzw_177",
  "date-picker-container": "awsui_date-picker-container_145pa_1vwzw_193",
  "date-picker-trigger": "awsui_date-picker-trigger_145pa_1vwzw_198",
  "date-picker-input": "awsui_date-picker-input_145pa_1vwzw_202",
  "open-calendar-button": "awsui_open-calendar-button_145pa_1vwzw_207"
};
  