
    import './styles.scoped.css';
    export default {
  "alert": "awsui_alert_mx3cw_7ht8j_189",
  "awsui-motion-fade-in": "awsui_awsui-motion-fade-in_mx3cw_7ht8j_1",
  "root": "awsui_root_mx3cw_7ht8j_211",
  "hidden": "awsui_hidden_mx3cw_7ht8j_246",
  "with-dismiss": "awsui_with-dismiss_mx3cw_7ht8j_293",
  "with-action": "awsui_with-action_mx3cw_7ht8j_293",
  "breakpoint-default": "awsui_breakpoint-default_mx3cw_7ht8j_299",
  "initial-hidden": "awsui_initial-hidden_mx3cw_7ht8j_310",
  "header": "awsui_header_mx3cw_7ht8j_315",
  "header-replacement": "awsui_header-replacement_mx3cw_7ht8j_316",
  "action": "awsui_action_mx3cw_7ht8j_320",
  "action-slot": "awsui_action-slot_mx3cw_7ht8j_325",
  "action-button": "awsui_action-button_mx3cw_7ht8j_326",
  "alert-focus-wrapper": "awsui_alert-focus-wrapper_mx3cw_7ht8j_330",
  "text": "awsui_text_mx3cw_7ht8j_359",
  "icon": "awsui_icon_mx3cw_7ht8j_366",
  "message": "awsui_message_mx3cw_7ht8j_369",
  "icon-size-medium": "awsui_icon-size-medium_mx3cw_7ht8j_377",
  "icon-size-big": "awsui_icon-size-big_mx3cw_7ht8j_380",
  "icon-size-normal": "awsui_icon-size-normal_mx3cw_7ht8j_383",
  "content": "awsui_content_mx3cw_7ht8j_387",
  "content-replacement": "awsui_content-replacement_mx3cw_7ht8j_388",
  "dismiss": "awsui_dismiss_mx3cw_7ht8j_392",
  "dismiss-button": "awsui_dismiss-button_mx3cw_7ht8j_397",
  "type-error": "awsui_type-error_mx3cw_7ht8j_401",
  "type-warning": "awsui_type-warning_mx3cw_7ht8j_409",
  "type-success": "awsui_type-success_mx3cw_7ht8j_417",
  "type-info": "awsui_type-info_mx3cw_7ht8j_425"
};
  