import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadHeader } from '../common/RadHeader'
import { RadContainer } from '../common/RadContainer'
import { RadContentLayout } from '../common/RadContentLayout'
import { RadBox } from '../common/RadBox'
import { RadLink } from '../common/RadLink'
import { RadButton } from '../common/RadButton'

export function PersonDetail () {
  const navigate = useNavigate()
  const { personId } = useParams()
  const { data: person } = useGet(`/api/person/${personId}`)
  const remove = useDelete(`/api/person/${personId}`, () => { navigate('/person') })
  const confirmDelete = useConfirm('Delete person?', 'Delete person permanently? This action cannot be undone.', () => { remove() })

  if (person != null) {
    return (
      <RadAppLayout
        name={person.name}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('edit')}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={false}>Delete</RadButton>
              </RadSpaceBetween>
            }
          >
            {person.name}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadContainer header={<RadHeader variant='h2'>Details</RadHeader>}>
              <RadContentLayout columns={3} borders='vertical'>
                <RadSpaceBetween size='l'>
                  <div>
                    <RadBox variant='awsui-key-label'>First name</RadBox>
                    <div>{person.firstName}</div>
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>Last name</RadBox>
                    <div>{person.lastName}</div>
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>Email</RadBox>
                    <div>{person.email ? <RadLink href={`mailto:${person.email}`}>{person.email}</RadLink> : '-'}</div>
                  </div>
                </RadSpaceBetween>
              </RadContentLayout>
            </RadContainer>
            <RadContainer header={<RadHeader variant='h2'>Organizations</RadHeader>}>
              <ul>
                <RadSpaceBetween size='s'>
                  {
                    person.organizations?.length > 0
                      ? person.organizations.map((org, idx) => <li key={idx}><RadLink href={`/organization/${org.id}`}>{org.name}</RadLink></li>)
                      : '-'
                  }
                </RadSpaceBetween>
              </ul>
            </RadContainer>
          </RadSpaceBetween>
        }
      />
    )
  }
}
