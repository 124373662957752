import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadContainer } from '../common/RadContainer'
import { RadTextContent } from '../common/RadTextContent'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadBox } from '../common/RadBox'
import { RadBadge } from '../common/RadBadge'
import { RadButton } from '../common/RadButton'
import { RadLink } from '../common/RadLink'

export function OrganizationDetail () {
  const navigate = useNavigate()
  const { organizationId } = useParams()
  const { data: organization } = useGet(organizationId != null ? `/api/organization/${organizationId}` : '')
  const remove = useDelete(`/api/organization/${organizationId}`, () => { navigate('/organization') })
  const confirmDelete = useConfirm('Delete organization?', 'Delete organization permanently? This action cannot be undone.', () => { remove() })

  function edit (evt) {
    evt.preventDefault()
    navigate('edit')
  }

  if (organization != null) {
    const focusAreas = organization.focusAreas.map(x => x.name)
    const people = organization.people.map(x => ({ name: x.name, email: x.email, id: x.id }))
    return (
      <RadAppLayout
        name={organization.name}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={edit}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={false}>Delete</RadButton>
              </RadSpaceBetween>
            }
          >
            {organization.name}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadContainer header={<RadHeader variant='h2'>Details</RadHeader>}>
              <RadColumnLayout columns={3} borders='vertical'>
                <div>
                  <RadBox variant='awsui-key-label'>Type</RadBox>
                  <div>{organization.organizationType?.name ?? '-'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>City</RadBox>
                  <div>{organization.city ?? '-'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>State</RadBox>
                  <div>{organization.state ?? '-'}</div>
                </div>
              </RadColumnLayout>
            </RadContainer>
            <RadContainer header={<RadHeader variant='h2'>Focus areas</RadHeader>}>
              {
                focusAreas.length > 0
                  ? (
                    <RadSpaceBetween direction='horizontal' size='xs'>
                      {focusAreas.sort().map((x, idx) => <RadBadge key={idx}>{x}</RadBadge>)}
                    </RadSpaceBetween>
                  )
                  : '-'
              }
            </RadContainer>
            <RadContainer header={<RadHeader variant='h2'>People</RadHeader>}>
              <RadTextContent>
                {
                  people.length > 0
                    ? (
                      <ul>
                        <RadSpaceBetween size='m'>
                          {
                            people.sort((a, b) => { return a.lastName - b.lastName })
                              .map((person, idx) => (
                                <li key={idx}>
                                  <RadLink href={`/person/${person.id}`}>{person.name} ({person.email})</RadLink>
                                </li>)
                              )
                          }
                        </RadSpaceBetween>
                      </ul>
                    )
                    : '-'
                }
              </RadTextContent>
            </RadContainer>
          </RadSpaceBetween>
        }
      />
    )
  }
}
