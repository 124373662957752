import React from 'react'
import { RadAppLayout } from './common/RadAppLayout'
import { RadHeader } from './common/RadHeader'

export function Home() {

  return (
    <RadAppLayout
      contentHeader={
        <RadHeader
          variant='h1'
        >
          Home
        </RadHeader>
      }
    />
  )
}
