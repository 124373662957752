import React, { useState, useEffect } from 'react'
import { useGet } from '../hooks/useGet'
import { isDateString } from '../common/utilities'
import { RadAdaptiveList } from '../common/RadAdaptiveList'
import { RadMultiSelect } from '../common/RadSelect'
import { RadFormField } from '../common/RadFormField'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadDatePicker } from '../common/RadDatePicker'
import { RadKeyValuePairs } from '../common/RadKeyValuePairs'

export function OrganizationList () {
  const { data: roleTypeOptions } = useGet('/api/option/projectOrganizationRoleType')
  const { data: orgFocusAreaOptions } = useGet('/api/option/organizationFocusArea')
  const { data: projFocusAreaOptions } = useGet('/api/option/projectFocusArea')
  const [statsPath, setStatsPath] = useState('/api/organization/statistics?')
  const { data: stats } = useGet(statsPath)
  const [filters, setFilters] = useState({
    roleType: [],
    orgFocusArea: [],
    projFocusArea: [],
    fromDate: null,
    toDate: null
  })

  useEffect(() => {
    // The user has entered two valid dates, ensure they are in the correct order
    if (isDateString(filters.fromDate) && isDateString(filters.toDate)) {
      const dates = [new Date(filters.fromDate), new Date(filters.toDate)]
      const [from, to] = [new Date(Math.min(...dates)), new Date(Math.max(...dates))]
      const [fromDateString, toDateString] = [from.toISOString().split('T')[0], to.toISOString().split('T')[0]]

      if (fromDateString !== filters.fromDate && toDateString !== filters.toDate) {
        setFilters({ ...filters, fromDate: fromDateString, toDate: toDateString })
      }
    }
  }, [filters])

  const doneLoading = roleTypeOptions != null && orgFocusAreaOptions != null && projFocusAreaOptions != null && stats != null

  if (doneLoading) {
    const selectedFilters = {
      roleType: roleTypeOptions.filter(x => filters.roleType.map(id => id).includes(x.value)),
      orgFocusArea: orgFocusAreaOptions.filter(x => filters.orgFocusArea.map(id => id).includes(x.value)),
      projFocusArea: projFocusAreaOptions.filter(x => filters.projFocusArea.map(id => id).includes(x.value))
    }

    return (
      <RadAdaptiveList
        entity='organization'
        fields={[
          { header: 'Name', name: 'name', link: true },
          { name: 'Type', field: 'organizationType.name' },
          'city',
          'state'
        ]}
        onQueryChanged={qs => setStatsPath(`${statsPath.split('?')[0]}?${qs}`)}
        filters={[{
          name: 'roleType',
          values: filters.roleType,
          component: (
            <RadFormField key='roleType' label="Organization's project role(s)">
              <RadMultiSelect
                expandToViewport
                selectedOptions={selectedFilters.roleType}
                onChange={({ detail }) =>
                  setFilters({
                    ...filters,
                    roleType: detail.selectedOptions.map(x => x.value)
                  })}
                options={roleTypeOptions}
                placeholder='Select organization project role'
              />
            </RadFormField>
          )
        }, {
          name: 'orgFocusArea',
          values: filters.orgFocusArea,
          component: (
            <RadFormField key='orgFocusArea' label='Organization focus area'>
              <RadMultiSelect
                expandToViewport
                selectedOptions={selectedFilters.orgFocusArea}
                onChange={({ detail }) =>
                  setFilters({
                    ...filters,
                    orgFocusArea: detail.selectedOptions.map(x => x.value)
                  })}
                options={orgFocusAreaOptions}
                placeholder='Select organization focus area'
              />
            </RadFormField>
          )
        }, {
          name: 'projFocusArea',
          values: filters.projFocusArea,
          component: (
            <RadFormField key='projFocusArea' label='Project focus area'>
              <RadMultiSelect
                expandToViewport
                selectedOptions={selectedFilters.projFocusArea}
                onChange={({ detail }) =>
                  setFilters({
                    ...filters,
                    projFocusArea: detail.selectedOptions.map(x => x.value)
                  })}
                options={projFocusAreaOptions}
                placeholder='Select project focus area'
              />
            </RadFormField>
          )
        }, {
          name: 'dates',
          values: [filters.fromDate, filters.toDate],
          component: (
            <RadSpaceBetween key='dates' direction='horizontal' size='s'>
              <RadFormField key='fromDate' label='Projects from' field='fromDate'>
                <RadDatePicker
                  onChange={({ detail }) => setFilters({
                    ...filters,
                    fromDate: detail.value
                  })}
                  value={filters.fromDate ?? ''}
                />
              </RadFormField>
              <RadFormField key='toDate' label='Projects to' field='toDate'>
                <RadDatePicker
                  onChange={({ detail }) => setFilters({
                    ...filters,
                    toDate: detail.value
                  })}
                  value={filters.toDate ?? ''}
                />
              </RadFormField>
            </RadSpaceBetween>
          )
        }]}
        expandableSections={[{
          key: 'statistics',
          headerText: 'Statistics',
          headerDescription: 'Statistics for these organizations',
          defaultExpanded: Object.keys(filters).some(k =>
            (Array.isArray(filters[k]) && filters[k].length > 0) ||
            (!Array.isArray(filters[k]) && filters[k] != null)
          ),
          component: (
            <RadSpaceBetween size='m'>
              <RadKeyValuePairs
                columns={1}
                items={[{
                  label: 'Involved with more than one project',
                  value: `${stats.multiProjectInvolvement}%`
                }]}
              />
            </RadSpaceBetween>
          )
        }]}
      />
    )
  }
}
