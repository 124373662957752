import React, { useState, useEffect } from 'react'
import { useGet } from '../hooks/useGet'
import { isDateString } from '../common/utilities'
import { RadAdaptiveList } from '../common/RadAdaptiveList'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadMultiSelect } from '../common/RadSelect'
import { RadFormField } from '../common/RadFormField'
import { RadDatePicker } from '../common/RadDatePicker'

export function ProjectList () {
  const { data: focusAreaOptions } = useGet('/api/option/projectFocusArea')
  const { data: statusOptions } = useGet('/api/option/projectStatusType?required=true')
  const { data: funderOptions } = useGet('/api/option/organization')
  const [filters, setFilters] = useState({
    focusArea: [],
    status: [],
    funder: [],
    fromDate: null,
    toDate: null
  })

  useEffect(() => {
    // The user has entered two valid dates, ensure they are in the correct order
    if (isDateString(filters.fromDate) && isDateString(filters.toDate)) {
      const dates = [new Date(filters.fromDate), new Date(filters.toDate)]
      const [from, to] = [new Date(Math.min(...dates)), new Date(Math.max(...dates))]
      const [fromDateString, toDateString] = [from.toISOString().split('T')[0], to.toISOString().split('T')[0]]

      if (fromDateString !== filters.fromDate && toDateString !== filters.toDate) {
        setFilters({ ...filters, fromDate: fromDateString, toDate: toDateString })
      }
    }
  }, [filters])

  const doneLoading = focusAreaOptions != null && statusOptions != null && funderOptions != null

  if (doneLoading) {
    const selectedFilters = {
      focusArea: focusAreaOptions.filter(x => filters.focusArea.map(id => id).includes(x.value)),
      status: statusOptions.filter(x => filters.status.map(id => id).includes(x.value)),
      funder: funderOptions.filter(x => filters.funder.map(id => id).includes(x.value))
    }

    return (
      <RadSpaceBetween>
        <RadSpaceBetween direction='horizontal' />
        <RadAdaptiveList
          entity='project'
          fields={[
            { header: 'Name', name: 'name', link: true },
            { name: 'Status', field: 'statusType.name' },
            { header: 'Start Date', name: 'startDateFormat' },
            { header: 'End Date', name: 'endDateFormat' }
          ]}
          filters={[{
            name: 'focusArea',
            values: filters.focusArea,
            component: (
              <RadFormField key='focusArea' label='Project focus area'>
                <RadMultiSelect
                  expandToViewport
                  selectedOptions={selectedFilters.focusArea}
                  onChange={({ detail }) =>
                    setFilters({
                      ...filters,
                      focusArea: detail.selectedOptions.map(x => x.value)
                    })}
                  options={focusAreaOptions}
                  placeholder='Select project focus area'
                />
              </RadFormField>
            )
          }, {
            name: 'status',
            values: filters.status,
            component: (
              <RadFormField key='status' label='Project status'>
                <RadMultiSelect
                  expandToViewport
                  selectedOptions={selectedFilters.status}
                  onChange={({ detail }) =>
                    setFilters({
                      ...filters,
                      status: detail.selectedOptions.map(x => x.value)
                    })}
                  options={statusOptions}
                  placeholder='Select project status'
                />
              </RadFormField>
            )
          }, {
            name: 'funder',
            values: filters.funder,
            component: (
              <RadFormField key='funder' label='Project funder'>
                <RadMultiSelect
                  expandToViewport
                  selectedOptions={selectedFilters.funder}
                  onChange={({ detail }) =>
                    setFilters({
                      ...filters,
                      funder: detail.selectedOptions.map(x => x.value)
                    })}
                  options={funderOptions}
                  placeholder='Select project funder'
                />
              </RadFormField>
            )
          }, {
            name: 'dates',
            values: [filters.fromDate, filters.toDate],
            component: (
              <RadSpaceBetween key='dates' direction='horizontal' size='s'>
                <RadFormField key='fromDate' label='From date' field='fromDate'>
                  <RadDatePicker
                    onChange={({ detail }) => setFilters({
                      ...filters,
                      fromDate: detail.value
                    })}
                    value={filters.fromDate ?? ''}
                  />
                </RadFormField>
                <RadFormField key='toDate' label='To date' field='toDate'>
                  <RadDatePicker
                    onChange={({ detail }) => setFilters({
                      ...filters,
                      toDate: detail.value
                    })}
                    value={filters.toDate ?? ''}
                  />
                </RadFormField>
              </RadSpaceBetween>
            )
          }]}
        />
      </RadSpaceBetween>
    )
  }
}
