import React from 'react'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { RadCards } from '../common/RadCards'
import { RadLink } from '../common/RadLink'
import { toTitleCase } from '../common/utilities'

export function OptionList () {
  const entities = [
    'organizationFocusArea',
    'organizationType',
    'projectAnalysisType',
    'projectFocusArea',
    'projectStatusType',
    'projectPhaseType',
    'projectOrganizationRoleType',
    'projectOrganizationParticipantRoleType'
  ]
  const { data: options } = useGet('/api/option')

  if (options != null) {
    return (
      <RadAppLayout
        contentHeader={<RadHeader variant='h1'>Options</RadHeader>}
        content={
          <RadCards
            cardDefinition={{
              header: item => (<RadLink fontSize='heading-m' href={`/option/${item}`}>{toTitleCase(item)}</RadLink>),
              sections: [
                {
                  id: 'types',
                  content: item => options.length > 0
                    ? (
                      <>
                        {options
                          .filter((type) => type.entity === item)
                          .map((type) => <div key={type.id}>{type.name}</div>)}
                      </>
                      )
                    : <div>-</div>
                }
              ]
            }}
            cardsPerRow={[
              { cards: 1 },
              { minWidth: 640, cards: 2 },
              { minWidth: 960, cards: 3 },
              { minWidth: 1280, cards: 4 }
            ]}
            items={entities}
            variant='full-page'
          />
        }
      />
    )
  }
}
