import React from 'react'
import Select from '@cloudscape-design/components/select'
import Multiselect from '@cloudscape-design/components/multiselect'

// https://cloudscape.design/components/select
export function RadSelect ({ filteringAriaLabel, ...rest }) {
  return (
    <Select
      {...rest}
      filteringAriaLabel={filteringAriaLabel ?? rest.filteringType != null ? 'search' : null}
    />
  )
}

export function RadMultiSelect ({ filteringAriaLabel, ...rest }) {
  return (
    <Multiselect
      {...rest}
      filteringAriaLabel={filteringAriaLabel ?? rest.filteringType != null ? 'search' : null}
    />
  )
}
