import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadContainer } from '../common/RadContainer'
import { RadGrid } from '../common/RadGrid'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadInput } from '../common/RadInput'
import { RadDatePicker } from '../common/RadDatePicker'
import { RadTextarea } from '../common/RadTextarea'
import { RadSelect, RadMultiSelect } from '../common/RadSelect'
import { RadButton } from '../common/RadButton'
import { RadCheckbox } from '../common/RadCheckbox'

export function ProjectEdit ({ selected }) {
  const navigate = useNavigate()
  const { projectId } = useParams()
  const [formValues, setFormValues] = useState()

  const { data: project } = useGet(projectId != null ? `/api/project/${projectId}` : '')
  const { data: statusTypeOptions } = useGet('/api/option/projectStatusType?required=true')
  const { data: focusAreaOptions } = useGet('/api/option/projectFocusArea?required=false')
  const { data: analysisTypesOptions } = useGet('/api/option/projectAnalysisType?required=false')

  const create = usePost('/api/project', formValues, (resp) => { navigate(`/project/${resp.id}`) })
  const update = usePut(`/api/project/${projectId}`, formValues, (resp) => { navigate(`/project/${projectId}`) })

  const isUpdate = projectId != null
  const loadForm =
    formValues != null &&
    statusTypeOptions != null &&
    focusAreaOptions != null &&
    analysisTypesOptions != null

  useEffect(() => {
    setFormValues(project ?? { focusAreas: [], analysisTypes: [], hasSupportContract: false })
  }, [project])

  async function saveChanges () {
    if (isUpdate) { update() } else { create() }
  }

  async function cancel () {
    if (isUpdate) {
      navigate(`/project/${projectId}`)
    } else {
      navigate('/project')
    }
  }

  if (loadForm) {
    const selectedFocusAreas = focusAreaOptions.filter(x => formValues.focusAreas.map(y => y.id.toString()).includes(x.value))
    const selectedAnalysisTypes = analysisTypesOptions.filter(x => formValues.analysisTypes.map(y => y.id.toString()).includes(x.value))
    return (
      <RadAppLayout
        name={formValues.name}
        contentHeader={
          <RadHeader variant='h1'>{formValues.id ? 'Edit' : 'New'} Project</RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadContainer header={<RadHeader variant='h1'>Details</RadHeader>}>
                  <RadSpaceBetween size='l'>
                    <RadFormField label='Name' field='name' required>
                      <RadInput
                        ariaRequired
                        placeholder='Enter name'
                        disabled={false}
                        value={formValues.name ?? ''}
                        onChange={event => setFormValues({ ...formValues, name: event.detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Description' field='description'>
                      <RadTextarea
                        onChange={({ detail }) => setFormValues({ ...formValues, description: detail.value })}
                        value={formValues.description}
                        placeholder='Enter a description for the project'
                      />
                    </RadFormField>

                    <RadFormField>
                      <RadGrid gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}>
                        <RadFormField label='Status' field='status'>
                          <RadSelect
                            selectedOption={formValues.statusTypeId ? statusTypeOptions.find(x => x.value === formValues.statusTypeId?.toString()) : { value: '', label: '-' }}
                            onChange={({ detail }) => {
                              if (detail.selectedOption.value !== '') {
                                setFormValues({ ...formValues, statusTypeId: parseInt(detail.selectedOption.value) })
                              } else {
                                setFormValues({ ...formValues, statusTypeId: null })
                              }
                            }}
                            options={statusTypeOptions}
                            selectedAriaLabel='Selected'
                            empty='No matches found'
                          />
                        </RadFormField>
                        <RadFormField label='Start date' field='startDate'>
                          <RadDatePicker
                            onChange={({ detail }) => setFormValues({ ...formValues, startDate: detail.value })}
                            value={formValues.startDate ?? ''}
                            openCalendarAriaLabel={selectedDate =>
                              'Choose project start date' +
                          (selectedDate
                            ? `, selected date is ${selectedDate}`
                            : '')}
                          />
                        </RadFormField>
                        <RadFormField label='End date' field='endDate'>
                          <RadDatePicker
                            onChange={({ detail }) => setFormValues({ ...formValues, endDate: detail.value })}
                            value={formValues.endDate ?? ''}
                            openCalendarAriaLabel={selectedDate =>
                              'Choose project start date' +
                          (selectedDate
                            ? `, selected date is ${selectedDate}`
                            : '')}
                          />
                        </RadFormField>
                      </RadGrid>
                    </RadFormField>
                    <RadFormField label='Focus areas' field='typeId'>
                      <RadMultiSelect
                        selectedOptions={selectedFocusAreas}
                        onChange={({ detail }) =>
                          setFormValues({ ...formValues, focusAreas: detail.selectedOptions.map(x => ({ id: parseInt(x.value) })) })}
                        options={focusAreaOptions}
                        placeholder='Choose options'
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>

                <RadContainer header={<RadHeader variant='h1'>Finance</RadHeader>}>
                  <RadFormField>
                    <RadGrid gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}>
                      <RadFormField label='Total cost' field='totalCost'>
                        <RadInput
                          ariaRequired
                          placeholder='Enter cost'
                          disabled={false}
                          value={formValues.totalCost ?? ''}
                          onChange={event => setFormValues({ ...formValues, totalCost: event.detail.value })}
                        />
                      </RadFormField>
                      <RadFormField label='Total Hours' field='totalHours'>
                        <RadInput
                          ariaRequired
                          placeholder='Enter hours'
                          disabled={false}
                          value={formValues.totalHours ?? ''}
                          onChange={event => setFormValues({ ...formValues, totalHours: event.detail.value })}
                        />
                      </RadFormField>
                      <RadFormField label='Support Contract' field='hasSupportContract'>
                        <RadCheckbox
                          onChange={({ detail }) => setFormValues({ ...formValues, hasSupportContract: !!detail.checked })}
                          checked={formValues.hasSupportContract}
                        />
                      </RadFormField>
                    </RadGrid>
                  </RadFormField>
                </RadContainer>

                <RadContainer header={<RadHeader variant='h1'>Methods</RadHeader>}>
                  <RadSpaceBetween size='l'>
                    <RadFormField>
                      <RadGrid gridDefinition={[{ colspan: 4 }, { colspan: 4 }]}>
                        <RadFormField label='Data coverage start date' field='dataCoverageStartDate'>
                          <RadDatePicker
                            onChange={({ detail }) => setFormValues({ ...formValues, dataCoverageStartDate: detail.value })}
                            value={formValues.dataCoverageStartDate ?? ''}
                            openCalendarAriaLabel={selectedDate =>
                              'Choose project start date' +
                            (selectedDate
                              ? `, selected date is ${selectedDate}`
                              : '')}
                          />
                        </RadFormField>
                        <RadFormField label='Data coverage end date' field='dataCoverageEndDate'>
                          <RadDatePicker
                            onChange={({ detail }) => setFormValues({ ...formValues, dataCoverageEndDate: detail.value })}
                            value={formValues.dataCoverageEndDate ?? ''}
                            openCalendarAriaLabel={selectedDate =>
                              'Choose project start date' +
                            (selectedDate
                              ? `, selected date is ${selectedDate}`
                              : '')}
                          />
                        </RadFormField>
                      </RadGrid>
                    </RadFormField>
                    <RadFormField label='Analysis types' field='typeId'>
                      <RadMultiSelect
                        selectedOptions={selectedAnalysisTypes}
                        onChange={({ detail }) =>
                          setFormValues({ ...formValues, analysisTypes: detail.selectedOptions.map(x => ({ id: parseInt(x.value) })) })}
                        options={analysisTypesOptions}
                        placeholder='Choose options'
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>

                {/* NOTES go here. */}
                <RadContainer header={<RadHeader variant='h1'>Notes</RadHeader>}>
                  <RadFormField label='Notes' field='notes'>
                    <RadTextarea
                      onChange={({ detail }) => setFormValues({ ...formValues, notes: detail.value })}
                      value={formValues.notes}
                      placeholder='Enter a notes for the project'
                    />
                  </RadFormField>
                </RadContainer>

                <RadContainer header={<RadHeader variant='h1'>Documents</RadHeader>}>
                  <RadSpaceBetween size='l'>
                    <RadFormField label='Charter Link' field='charterLink'>
                      <RadInput
                        ariaRequired
                        placeholder='Enter link'
                        disabled={false}
                        value={formValues.charterLink ?? ''}
                        onChange={event => setFormValues({ ...formValues, charterLink: event.detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Retro notes link' field='retroNotesLink'>
                      <RadInput
                        ariaRequired
                        placeholder='Enter link'
                        disabled={false}
                        value={formValues.retroNotesLink ?? ''}
                        onChange={event => setFormValues({ ...formValues, retroNotesLink: event.detail.value })}
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}
