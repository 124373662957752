
    import './styles.scoped.css';
    export default {
  "resize-active": "awsui_resize-active_x7peu_i88k3_141",
  "resize-active-with-focus": "awsui_resize-active-with-focus_x7peu_i88k3_141",
  "divider": "awsui_divider_x7peu_i88k3_146",
  "divider-disabled": "awsui_divider-disabled_x7peu_i88k3_160",
  "divider-active": "awsui_divider-active_x7peu_i88k3_163",
  "resizer": "awsui_resizer_x7peu_i88k3_167",
  "has-focus": "awsui_has-focus_x7peu_i88k3_221",
  "tracker": "awsui_tracker_x7peu_i88k3_246"
};
  