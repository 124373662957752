import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { useGet } from '../hooks/useGet'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadContainer } from '../common/RadContainer'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadInput } from '../common/RadInput'
import { RadSelect, RadMultiSelect } from '../common/RadSelect'
import { RadAttributeEditor } from '../common/RadAttributeEditor'
import { RadButton } from '../common/RadButton'

export function OrganizationEdit ({ selected }) {
  const navigate = useNavigate()
  const { organizationId } = useParams()
  const [formValues, setFormValues] = useState()
  const [personFilterText, setPersonFilterText] = useState('')

  const { data: organization } = useGet(organizationId != null ? `/api/organization/${organizationId}` : '')
  const { data: organizationTypeOptions } = useGet('/api/option/organizationType?required=true')
  const { data: focusAreaOptions } = useGet('/api/option/organizationFocusArea?required=false')
  const { data: personOptions } = useGet(`/api/option/person?search=${personFilterText}`)

  const create = usePost('/api/organization', formValues, (resp) => { navigate(`/organization/${resp.id}`) })
  const update = usePut(`/api/organization/${organizationId}`, formValues, (resp) => { navigate(`/organization/${organizationId}`) })

  const isUpdate = organizationId != null
  const loadForm =
    formValues != null &&
    organizationTypeOptions != null &&
    focusAreaOptions != null &&
    personOptions != null

  useEffect(() => {
    setFormValues(organization ?? { focusAreas: [], people: [] })
  }, [organization])

  async function saveChanges () {
    if (isUpdate) { update() } else { create() }
  }

  async function cancel () {
    if (isUpdate) {
      navigate(`/organization/${organizationId}`)
    } else {
      navigate('/organization')
    }
  }

  if (loadForm) {
    const selectedFocusAreas = focusAreaOptions.filter(x => formValues.focusAreas.map(y => y.id.toString()).includes(x.value))
    return (
      <RadAppLayout
        name={formValues.name}
        contentHeader={
          <RadHeader variant='h1'>{formValues.id ? 'Edit' : 'New'} Organization</RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadContainer header={<RadHeader variant='h1'>Details</RadHeader>}>
                  <RadSpaceBetween size='l'>
                    <RadFormField label='Name' field='name' required>
                      <RadInput
                        ariaRequired
                        placeholder='Enter name'
                        disabled={false}
                        value={formValues.name ?? ''}
                        onChange={event => setFormValues({ ...formValues, name: event.detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='City' field='city'>
                      <RadInput
                        ariaRequired
                        placeholder='Enter city'
                        disabled={false}
                        value={formValues.city ?? ''}
                        onChange={event => setFormValues({ ...formValues, city: event.detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='State' field='state'>
                      <RadInput
                        ariaRequired
                        placeholder='Enter state'
                        disabled={false}
                        value={formValues.state ?? ''}
                        onChange={event => setFormValues({ ...formValues, state: event.detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Type' field='typeId'>
                      <RadSelect
                        selectedOption={formValues.typeId ? organizationTypeOptions.find(x => x.value === formValues.typeId?.toString()) : { value: '', label: '-' }}
                        onChange={({ detail }) => {
                          if (detail.selectedOption.value !== '') {
                            setFormValues({ ...formValues, typeId: parseInt(detail.selectedOption.value) })
                          } else {
                            setFormValues({ ...formValues, typeId: null })
                          }
                        }}
                        options={organizationTypeOptions}
                        selectedAriaLabel='Selected'
                        empty='No matches found'
                      />
                    </RadFormField>
                    <RadFormField label='Focus areas' field='typeId'>
                      <RadMultiSelect
                        selectedOptions={selectedFocusAreas}
                        onChange={({ detail }) =>
                          setFormValues({ ...formValues, focusAreas: detail.selectedOptions.map(x => ({ id: parseInt(x.value) })) })}
                        options={focusAreaOptions}
                        placeholder='Choose options'
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>
                <RadContainer header={<RadHeader variant='h1'>People</RadHeader>}>
                  <RadSpaceBetween size='l'>
                    <RadAttributeEditor
                      onAddButtonClick={() => {
                        const people = [...formValues.people]
                        people.push({ uuid: uuidv4() })
                        setFormValues({ ...formValues, people })
                      }}
                      onRemoveButtonClick={({ detail: { itemIndex } }) => {
                        const people = [...formValues.people]
                        people.splice(itemIndex, 1)
                        setFormValues({ ...formValues, people })
                      }}
                      items={formValues.people}
                      addButtonText='Add new person'
                      definition={[
                        {
                          label: 'People',
                          control: item => {
                            const filteredOptions = (personOptions ?? []).filter((x) => !formValues.people.map((y) => y.id?.toString()).includes(x.value))
                            const selectedOption = item.id ? { value: item.id.toString(), label: item.selectListName } : null
                            return (
                              <RadFormField field={`people.${item.id || item.uuid}.id`}>
                                <RadSelect
                                  selectedOption={selectedOption}
                                  onChange={({ detail }) => {
                                    const people = formValues.people
                                    const person = formValues.people.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                                    person.id = parseInt(detail.selectedOption.value)
                                    person.selectListName = detail.selectedOption.label
                                    setFormValues({ ...formValues, people })
                                  }}
                                  options={filteredOptions}
                                  enteredTextLabel={value => value}
                                  selectedAriaLabel='Selected'
                                  placeholder='Choose a person'
                                  filteringType='manual'
                                  onLoadItems={({ detail }) => {
                                    setPersonFilterText(detail.filteringText)
                                  }}
                                  empty={personFilterText ? 'No matches found' : null}
                                />
                              </RadFormField>
                            )
                          }
                        }
                      ]}
                      removeButtonText='Remove'
                      empty='No people added to this organization.'
                    />
                  </RadSpaceBetween>
                </RadContainer>

              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}
