
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import * as firebaseui from 'firebaseui'

const firebaseConfig = {
  apiKey: 'AIzaSyCpx7EzrqE-h8D7HxHydDL6sBhipVJyS5o',
  authDomain: 'metadata-tracker-439614.firebaseapp.com',
  projectId: 'metadata-tracker-439614'
}

firebase.initializeApp(firebaseConfig)
const auth = firebase.auth()
const ui = new firebaseui.auth.AuthUI(auth)

export { firebase, auth, ui }
