
    import './styles.scoped.css';
    export default {
  "key-value-pairs": "awsui_key-value-pairs_1y9fy_1qncq_141",
  "group-title": "awsui_group-title_1y9fy_1qncq_173",
  "group-list": "awsui_group-list_1y9fy_1qncq_177",
  "group-list-item": "awsui_group-list-item_1y9fy_1qncq_187",
  "term": "awsui_term_1y9fy_1qncq_191",
  "key-label": "awsui_key-label_1y9fy_1qncq_199",
  "detail": "awsui_detail_1y9fy_1qncq_207",
  "info": "awsui_info_1y9fy_1qncq_242"
};
  