import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadInput } from '../common/RadInput'
import { RadButton } from '../common/RadButton'

export function PersonEdit ({ selected }) {
  const navigate = useNavigate()
  const { personId } = useParams()
  const [formValues, setFormValues] = useState({})

  const { data: person } = useGet(personId != null ? `/api/person/${personId}` : '')

  const create = usePost('/api/person', formValues, (resp) => { navigate(`/person/${resp.id}`) })
  const update = usePut(`/api/person/${personId}`, formValues, (resp) => { navigate(`/person/${personId}`) })

  const isUpdate = personId != null
  const loadForm = formValues != null

  useEffect(() => {
    setFormValues(person ?? {})
  }, [person])

  async function saveChanges () {
    if (isUpdate) { update() } else { create() }
  }

  async function cancel () {
    if (isUpdate) {
      navigate(`/person/${personId}`)
    } else {
      navigate('/person')
    }
  }

  if (loadForm) {
    return (
      <RadAppLayout
        name={formValues.name}
        contentHeader={<RadHeader variant='h1'>{formValues.id ? 'Edit' : 'New'} Person</RadHeader>}
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadFormField label='First name' field='firstName' required>
                  <RadInput
                    ariaRequired
                    placeholder='Enter first name'
                    disabled={false}
                    value={formValues.firstName ?? ''}
                    onChange={event => setFormValues({ ...formValues, firstName: event.detail.value })}
                  />
                </RadFormField>
                <RadFormField label='Last name' field='lastName' required>
                  <RadInput
                    ariaRequired
                    placeholder='Enter last name'
                    disabled={false}
                    value={formValues.lastName ?? ''}
                    onChange={event => setFormValues({ ...formValues, lastName: event.detail.value })}
                  />
                </RadFormField>
                <RadFormField label='Email' field='email' required>
                  <RadInput
                    ariaRequired
                    placeholder='Enter email'
                    disabled={false}
                    value={formValues.email ?? ''}
                    onChange={event => setFormValues({ ...formValues, email: event.detail.value })}
                  />
                </RadFormField>
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}
