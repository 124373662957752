
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_7nfqu_94zi3_149",
  "has-adaptive-widths-default": "awsui_has-adaptive-widths-default_7nfqu_94zi3_193",
  "has-adaptive-widths-dashboard": "awsui_has-adaptive-widths-dashboard_7nfqu_94zi3_198",
  "navigation": "awsui_navigation_7nfqu_94zi3_213",
  "tools": "awsui_tools_7nfqu_94zi3_214",
  "global-tools": "awsui_global-tools_7nfqu_94zi3_215",
  "tools-open": "awsui_tools-open_7nfqu_94zi3_253",
  "split-panel-side": "awsui_split-panel-side_7nfqu_94zi3_266",
  "split-panel-bottom": "awsui_split-panel-bottom_7nfqu_94zi3_273",
  "panel-hidden": "awsui_panel-hidden_7nfqu_94zi3_286",
  "toolbar-container": "awsui_toolbar-container_7nfqu_94zi3_296",
  "notifications-container": "awsui_notifications-container_7nfqu_94zi3_302",
  "notifications-background": "awsui_notifications-background_7nfqu_94zi3_306",
  "main-landmark": "awsui_main-landmark_7nfqu_94zi3_317",
  "main": "awsui_main_7nfqu_94zi3_317",
  "main-disable-paddings": "awsui_main-disable-paddings_7nfqu_94zi3_326",
  "content-header": "awsui_content-header_7nfqu_94zi3_336",
  "content": "awsui_content_7nfqu_94zi3_336",
  "unfocusable-mobile": "awsui_unfocusable-mobile_7nfqu_94zi3_346"
};
  