
    import './styles.scoped.css';
    export default {
  "link": "awsui_link_4c84z_svk5z_141",
  "variant-secondary": "awsui_variant-secondary_4c84z_svk5z_207",
  "variant-primary": "awsui_variant-primary_4c84z_svk5z_242",
  "variant-info": "awsui_variant-info_4c84z_svk5z_275",
  "variant-value-large": "awsui_variant-value-large_4c84z_svk5z_310",
  "variant-top-navigation": "awsui_variant-top-navigation_4c84z_svk5z_342",
  "variant-recovery": "awsui_variant-recovery_4c84z_svk5z_375",
  "button": "awsui_button_4c84z_svk5z_408",
  "color-inverted": "awsui_color-inverted_4c84z_svk5z_443",
  "font-size-body-s": "awsui_font-size-body-s_4c84z_svk5z_464",
  "font-size-body-m": "awsui_font-size-body-m_4c84z_svk5z_471",
  "font-size-heading-xs": "awsui_font-size-heading-xs_4c84z_svk5z_477",
  "font-size-heading-s": "awsui_font-size-heading-s_4c84z_svk5z_483",
  "font-size-heading-m": "awsui_font-size-heading-m_4c84z_svk5z_490",
  "font-size-heading-l": "awsui_font-size-heading-l_4c84z_svk5z_497",
  "font-size-heading-xl": "awsui_font-size-heading-xl_4c84z_svk5z_504",
  "font-size-display-l": "awsui_font-size-display-l_4c84z_svk5z_511",
  "font-size-inherit": "awsui_font-size-inherit_4c84z_svk5z_518",
  "icon-wrapper": "awsui_icon-wrapper_4c84z_svk5z_526",
  "icon": "awsui_icon_4c84z_svk5z_526"
};
  