
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_1i0s3_1e7qo_141",
  "header": "awsui_header_1i0s3_1e7qo_173",
  "content": "awsui_content_1i0s3_1e7qo_177",
  "error": "awsui_error_1i0s3_1e7qo_181",
  "footer": "awsui_footer_1i0s3_1e7qo_185",
  "actions-section": "awsui_actions-section_1i0s3_1e7qo_189",
  "secondary-actions": "awsui_secondary-actions_1i0s3_1e7qo_198",
  "actions": "awsui_actions_1i0s3_1e7qo_189"
};
  