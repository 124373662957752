
    import './styles.scoped.css';
    export default {
  "button": "awsui_button_m5h9f_12y39_141",
  "expanded": "awsui_expanded_m5h9f_12y39_193",
  "offset-right-none": "awsui_offset-right-none_m5h9f_12y39_204",
  "offset-right-l": "awsui_offset-right-l_m5h9f_12y39_207",
  "offset-right-xxl": "awsui_offset-right-xxl_m5h9f_12y39_210",
  "text": "awsui_text_m5h9f_12y39_235",
  "icon": "awsui_icon_m5h9f_12y39_239"
};
  