
    import './styles.scoped.css';
    export default {
  "icon": "awsui_icon_k5dlb_1ulj1_149",
  "top-navigation": "awsui_top-navigation_k5dlb_1ulj1_163",
  "padding-box": "awsui_padding-box_k5dlb_1ulj1_195",
  "medium": "awsui_medium_k5dlb_1ulj1_205",
  "narrow": "awsui_narrow_k5dlb_1ulj1_205",
  "virtual": "awsui_virtual_k5dlb_1ulj1_216",
  "hidden": "awsui_hidden_k5dlb_1ulj1_220",
  "\t": "awsui_\t_k5dlb_1ulj1_1",
  "identity": "awsui_identity_k5dlb_1ulj1_232",
  "identity-link": "awsui_identity-link_k5dlb_1ulj1_235",
  "no-logo": "awsui_no-logo_k5dlb_1ulj1_255",
  "logo": "awsui_logo_k5dlb_1ulj1_259",
  "title": "awsui_title_k5dlb_1ulj1_271",
  "inputs": "awsui_inputs_k5dlb_1ulj1_286",
  "search": "awsui_search_k5dlb_1ulj1_294",
  "search-expanded": "awsui_search-expanded_k5dlb_1ulj1_298",
  "utilities": "awsui_utilities_k5dlb_1ulj1_302",
  "utility-wrapper": "awsui_utility-wrapper_k5dlb_1ulj1_313",
  "utility-type-button-link": "awsui_utility-type-button-link_k5dlb_1ulj1_334",
  "utility-type-menu-dropdown": "awsui_utility-type-menu-dropdown_k5dlb_1ulj1_339",
  "utility-type-button-primary-button": "awsui_utility-type-button-primary-button_k5dlb_1ulj1_348",
  "utility-link-icon": "awsui_utility-link-icon_k5dlb_1ulj1_356",
  "utility-button-external-icon": "awsui_utility-button-external-icon_k5dlb_1ulj1_360",
  "offset-right-none": "awsui_offset-right-none_k5dlb_1ulj1_364",
  "offset-right-l": "awsui_offset-right-l_k5dlb_1ulj1_368",
  "offset-right-xxl": "awsui_offset-right-xxl_k5dlb_1ulj1_372",
  "overflow-menu-drawer": "awsui_overflow-menu-drawer_k5dlb_1ulj1_377",
  "overflow-menu": "awsui_overflow-menu_k5dlb_1ulj1_377",
  "overflow-menu-header": "awsui_overflow-menu-header_k5dlb_1ulj1_418",
  "overflow-menu-header-text": "awsui_overflow-menu-header-text_k5dlb_1ulj1_426",
  "overflow-menu-header-text--secondary": "awsui_overflow-menu-header-text--secondary_k5dlb_1ulj1_438",
  "overflow-menu-header-text--title": "awsui_overflow-menu-header-text--title_k5dlb_1ulj1_444",
  "overflow-menu-back-button": "awsui_overflow-menu-back-button_k5dlb_1ulj1_448",
  "overflow-menu-dismiss-button": "awsui_overflow-menu-dismiss-button_k5dlb_1ulj1_452",
  "overflow-menu-control": "awsui_overflow-menu-control_k5dlb_1ulj1_456",
  "overflow-menu-list-item-utility": "awsui_overflow-menu-list-item-utility_k5dlb_1ulj1_494",
  "overflow-menu-control-link": "awsui_overflow-menu-control-link_k5dlb_1ulj1_502",
  "overflow-menu-control-expandable-menu-trigger": "awsui_overflow-menu-control-expandable-menu-trigger_k5dlb_1ulj1_505",
  "overflow-menu-list": "awsui_overflow-menu-list_k5dlb_1ulj1_494",
  "overflow-menu-list-submenu": "awsui_overflow-menu-list-submenu_k5dlb_1ulj1_541",
  "overflow-menu-list-item": "awsui_overflow-menu-list-item_k5dlb_1ulj1_494",
  "overflow-menu-list-item-icon": "awsui_overflow-menu-list-item-icon_k5dlb_1ulj1_550",
  "overflow-menu-list-item-text": "awsui_overflow-menu-list-item-text_k5dlb_1ulj1_553",
  "overflow-menu-list-item-submenu": "awsui_overflow-menu-list-item-submenu_k5dlb_1ulj1_562",
  "overflow-menu-list-item-dropdown-menu": "awsui_overflow-menu-list-item-dropdown-menu_k5dlb_1ulj1_568",
  "overflow-menu-list-item-expandable": "awsui_overflow-menu-list-item-expandable_k5dlb_1ulj1_572",
  "expanded": "awsui_expanded_k5dlb_1ulj1_580"
};
  