import moment from 'moment'
import * as pluralizer from 'pluralize'

export const removeEmptyStrings = (obj) => {
  const isArray = Array.isArray(obj)
  for (const k of Object.keys(obj)) {
    if (obj[k] !== null && obj[k] !== undefined && typeof obj[k] === 'string' && obj[k].trim() === '') {
      obj[k] = null
    } else if (obj[k] !== null && obj[k] !== undefined && typeof obj[k] === 'object') {
      removeEmptyStrings(obj[k])
    }
    if (isArray && obj.length === k) {
      removeEmptyStrings(obj)
    }
  }
  return obj
}

export function formatDate (inputString) {
  if (inputString == null) {
    return null
  }
  return moment(inputString).format('MM/DD/YYYY')
}

export function formatDateTime (inputString) {
  if (inputString == null) {
    return null
  }
  return moment(inputString).format('MM/DD/YYYY h:mm A')
}

export function isDateString (inputString) {
  return moment(inputString, 'YYYY-MM-DD', true).isValid()
}

export function getOrdinal (number) {
  switch (number) {
    case undefined:
      return ''
    case 1:
      return '1st'
    case 2:
      return '2nd'
    case 3:
      return '3rd'
    default:
      return `${number}th`
  }
}

export function toTitleCase (str) {
  return str.replace(/([A-Z])/g, ' $1').replace(str[0], str[0].toUpperCase())
}

export function getBreadcrumbName (value) {
  return pluralizer(toTitleCase(value))
    .replaceAll('-', ' ')
    .replaceAll('Apis', 'APIs')
}
