
    import './styles.scoped.css';
    export default {
  "background": "awsui_background_hyvsj_uh0or_145",
  "scrolling-background": "awsui_scrolling-background_hyvsj_uh0or_148",
  "breadcrumbs": "awsui_breadcrumbs_hyvsj_uh0or_159",
  "drawers-container": "awsui_drawers-container_hyvsj_uh0or_172",
  "has-open-drawer": "awsui_has-open-drawer_hyvsj_uh0or_183",
  "disable-body-scroll": "awsui_disable-body-scroll_hyvsj_uh0or_204",
  "drawers-desktop-triggers-container": "awsui_drawers-desktop-triggers-container_hyvsj_uh0or_209",
  "has-multiple-triggers": "awsui_has-multiple-triggers_hyvsj_uh0or_247",
  "drawers-mobile-triggers-container": "awsui_drawers-mobile-triggers-container_hyvsj_uh0or_257",
  "drawers-trigger-content": "awsui_drawers-trigger-content_hyvsj_uh0or_262",
  "drawers-trigger-overflow": "awsui_drawers-trigger-overflow_hyvsj_uh0or_276",
  "drawers-trigger": "awsui_drawers-trigger_hyvsj_uh0or_262",
  "drawer": "awsui_drawer_hyvsj_uh0or_172",
  "drawer-content-container": "awsui_drawer-content-container_hyvsj_uh0or_307",
  "drawer-close-button": "awsui_drawer-close-button_hyvsj_uh0or_316",
  "drawer-content": "awsui_drawer-content_hyvsj_uh0or_307",
  "drawer-content-hidden": "awsui_drawer-content-hidden_hyvsj_uh0or_325",
  "drawer-slider": "awsui_drawer-slider_hyvsj_uh0or_328",
  "is-drawer-open": "awsui_is-drawer-open_hyvsj_uh0or_335",
  "content": "awsui_content_hyvsj_uh0or_361",
  "layout": "awsui_layout_hyvsj_uh0or_386",
  "has-max-content-width": "awsui_has-max-content-width_hyvsj_uh0or_458",
  "content-type-dashboard": "awsui_content-type-dashboard_hyvsj_uh0or_473",
  "content-type-table": "awsui_content-type-table_hyvsj_uh0or_487",
  "content-type-cards": "awsui_content-type-cards_hyvsj_uh0or_487",
  "is-overlap-disabled": "awsui_is-overlap-disabled_hyvsj_uh0or_493",
  "is-hide-mobile-toolbar": "awsui_is-hide-mobile-toolbar_hyvsj_uh0or_496",
  "has-content-gap-left": "awsui_has-content-gap-left_hyvsj_uh0or_510",
  "has-content-gap-right": "awsui_has-content-gap-right_hyvsj_uh0or_513",
  "has-breadcrumbs": "awsui_has-breadcrumbs_hyvsj_uh0or_523",
  "content-first-child-header": "awsui_content-first-child-header_hyvsj_uh0or_526",
  "content-first-child-notifications": "awsui_content-first-child-notifications_hyvsj_uh0or_526",
  "has-header": "awsui_has-header_hyvsj_uh0or_526",
  "content-first-child-main": "awsui_content-first-child-main_hyvsj_uh0or_541",
  "disable-content-paddings": "awsui_disable-content-paddings_hyvsj_uh0or_541",
  "has-left-toggles-gutter": "awsui_has-left-toggles-gutter_hyvsj_uh0or_561",
  "has-right-toggles-gutter": "awsui_has-right-toggles-gutter_hyvsj_uh0or_564",
  "has-split-panel": "awsui_has-split-panel_hyvsj_uh0or_584",
  "split-panel-position-bottom": "awsui_split-panel-position-bottom_hyvsj_uh0or_584",
  "block-body-scroll": "awsui_block-body-scroll_hyvsj_uh0or_592",
  "unfocusable": "awsui_unfocusable_hyvsj_uh0or_597",
  "container": "awsui_container_hyvsj_uh0or_607",
  "is-navigation-open": "awsui_is-navigation-open_hyvsj_uh0or_633",
  "is-tools-open": "awsui_is-tools-open_hyvsj_uh0or_636",
  "is-split-panel-open": "awsui_is-split-panel-open_hyvsj_uh0or_636",
  "split-panel-position-side": "awsui_split-panel-position-side_hyvsj_uh0or_636",
  "has-active-drawer": "awsui_has-active-drawer_hyvsj_uh0or_636",
  "mobile-toolbar": "awsui_mobile-toolbar_hyvsj_uh0or_649",
  "remove-high-contrast-header": "awsui_remove-high-contrast-header_hyvsj_uh0or_666",
  "mobile-toolbar-nav": "awsui_mobile-toolbar-nav_hyvsj_uh0or_670",
  "mobile-toolbar-breadcrumbs": "awsui_mobile-toolbar-breadcrumbs_hyvsj_uh0or_674",
  "mobile-toolbar-tools": "awsui_mobile-toolbar-tools_hyvsj_uh0or_678",
  "navigation-container": "awsui_navigation-container_hyvsj_uh0or_687",
  "show-navigation": "awsui_show-navigation_hyvsj_uh0or_725",
  "animating": "awsui_animating_hyvsj_uh0or_748",
  "showButtons": "awsui_showButtons_hyvsj_uh0or_1",
  "navigation": "awsui_navigation_hyvsj_uh0or_687",
  "openNavigation": "awsui_openNavigation_hyvsj_uh0or_1",
  "animated-content": "awsui_animated-content_hyvsj_uh0or_812",
  "content-container": "awsui_content-container_hyvsj_uh0or_815",
  "hide-navigation": "awsui_hide-navigation_hyvsj_uh0or_824",
  "notifications": "awsui_notifications_hyvsj_uh0or_834",
  "has-notification-content": "awsui_has-notification-content_hyvsj_uh0or_839",
  "sticky-notifications": "awsui_sticky-notifications_hyvsj_uh0or_843",
  "high-contrast": "awsui_high-contrast_hyvsj_uh0or_848",
  "split-panel-bottom": "awsui_split-panel-bottom_hyvsj_uh0or_866",
  "position-bottom": "awsui_position-bottom_hyvsj_uh0or_913",
  "openSplitPanelBottom": "awsui_openSplitPanelBottom_hyvsj_uh0or_1",
  "split-panel-side": "awsui_split-panel-side_hyvsj_uh0or_942",
  "position-side": "awsui_position-side_hyvsj_uh0or_955",
  "tools-container": "awsui_tools-container_hyvsj_uh0or_977",
  "tools": "awsui_tools_hyvsj_uh0or_977",
  "openTools": "awsui_openTools_hyvsj_uh0or_1",
  "has-tools-form-persistence": "awsui_has-tools-form-persistence_hyvsj_uh0or_1066",
  "hide-tools": "awsui_hide-tools_hyvsj_uh0or_1076",
  "show-tools": "awsui_show-tools_hyvsj_uh0or_1088",
  "has-tools-form": "awsui_has-tools-form_hyvsj_uh0or_1066",
  "trigger-button-styles": "awsui_trigger-button-styles_hyvsj_uh0or_1168",
  "trigger-badge-wrapper": "awsui_trigger-badge-wrapper_hyvsj_uh0or_1184",
  "trigger": "awsui_trigger_hyvsj_uh0or_1168",
  "selected": "awsui_selected_hyvsj_uh0or_1236",
  "badge": "awsui_badge_hyvsj_uh0or_1256",
  "trigger-wrapper": "awsui_trigger-wrapper_hyvsj_uh0or_1260",
  "trigger-wrapper-tooltip-visible": "awsui_trigger-wrapper-tooltip-visible_hyvsj_uh0or_1271",
  "dot": "awsui_dot_hyvsj_uh0or_1275",
  "trigger-tooltip": "awsui_trigger-tooltip_hyvsj_uh0or_1288"
};
  