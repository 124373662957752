import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { usePost } from '../hooks/usePost'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadContainer } from '../common/RadContainer'
import { RadContentLayout } from '../common/RadContentLayout'
import { RadBox } from '../common/RadBox'
import { RadGrid } from '../common/RadGrid'
import { RadCards } from '../common/RadCards'
import { RadBadge } from '../common/RadBadge'
import { RadButton } from '../common/RadButton'
import { RadLink } from '../common/RadLink'
import { RadTabs } from '../common/RadTabs'
import { RadStatusIndicator } from '../common/RadStatusIndicator'
import { RadModal } from '../common/RadModal'
import { RadFormField } from '../common/RadFormField'
import { RadSelect } from '../common/RadSelect'
import { RadInput } from '../common/RadInput'
import { RadTextarea } from '../common/RadTextarea'
import { RadDatePicker } from '../common/RadDatePicker'
import { formatDate } from '../common/utilities'
import { RadKeyValuePairs } from '../common/RadKeyValuePairs'

export function ProjectDetail () {
  const navigate = useNavigate()
  const { projectId } = useParams()
  const { data: project } = useGet(projectId != null ? `/api/project/${projectId}` : '')
  const { data: organizationOptions } = useGet('/api/option/organization')
  const remove = useDelete(`/api/project/${projectId}`, () => { navigate('/project') })
  const confirmDelete = useConfirm('Delete project?', 'Delete project permanently? This action cannot be undone.', () => { remove() })

  function edit (evt) {
    evt.preventDefault()
    navigate('edit')
  }

  if (project != null && organizationOptions != null) {
    const focusAreas = project.focusAreas.map(x => x.name)
    const analysisTypes = project.analysisTypes.map(x => x.name)
    const funders = project.organizations
      .filter(x => x.roleTypes.map(r => r.name).includes('Funder'))
      .map(o => o.organization.name)
    let statusBadge = {}
    switch (project.statusType?.name) {
      case undefined:
        statusBadge = { status: 'Inactive', type: 'in-progress' }
        break
      case 'Active':
        statusBadge = { status: 'Active', type: 'pending' }
        break
      case 'Paused':
        statusBadge = { status: 'Paused', type: 'warning' }
        break
      case 'Completed':
        statusBadge = { status: 'Completed', type: 'success' }
        break
      case 'Cancelled':
        statusBadge = { status: 'Cancelled', type: 'error' }
        break
      default:
        statusBadge = { status: '', type: 'in-progress' }
    }

    return (
      <RadAppLayout
        name={project.name}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={edit}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={false}>Delete</RadButton>
              </RadSpaceBetween>
            }
          >
            {project.name}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='xxl'>
            <RadContainer header={
              <RadGrid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                <RadBox variant='h3'>Highlights</RadBox>
                <RadBox textAlign='right'>
                  <RadStatusIndicator type={statusBadge.type}>{statusBadge.status}</RadStatusIndicator>
                </RadBox>
              </RadGrid>
            }
            >
              <RadSpaceBetween size='s'>
                <div>
                  <RadBox variant='awsui-key-label'>Description</RadBox>
                  <div>{project.description ?? '-'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>Focus areas</RadBox>
                  <div>
                    {
                      focusAreas.length > 0
                        ? (
                          <RadSpaceBetween direction='horizontal' size='xs'>
                            {focusAreas.sort().map((x, idx) => <RadBadge key={idx}>{x}</RadBadge>)}
                          </RadSpaceBetween>
                          )
                        : '-'
                    }
                  </div>
                </div>
                <RadGrid gridDefinition={[
                  { colspan: { default: 12, xs: 3 } },
                  { colspan: { default: 12, xs: 3 } },
                  { colspan: { default: 12, xs: 3 } },
                  { colspan: { default: 12, xs: 3 } }
                ]}
                >
                  <div>
                    <RadBox variant='awsui-key-label'>Funders</RadBox>
                    <RadBox>{funders.length > 0 ? funders.join(', ') : '-'}</RadBox>
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>Start date</RadBox>
                    <div>{project.startDate != null ? formatDate(project.startDate) : '-'}</div>
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>End date</RadBox>
                    <div>{project.endDate != null ? formatDate(project.endDate) : '-'}</div>
                  </div>
                </RadGrid>

              </RadSpaceBetween>
            </RadContainer>

            <RadTabs
              tabs={[
                {
                  label: 'Organizations',
                  id: 'orgs',
                  content: <Organizations {...{ project, organizationOptions, navigate }} />
                },
                {
                  label: 'Finance',
                  id: 'finance',
                  content: <Finance {...{ project }} />
                },
                {
                  label: 'Methods',
                  id: 'methods',
                  content: <Methods {...{ project, analysisTypes }} />
                },
                {
                  label: 'Notes',
                  id: 'notes',
                  content: <Notes {...{ project }} />
                },
                {
                  label: 'Documents',
                  id: 'docs',
                  content: <Documents {...{ project }} />
                },
                {
                  label: 'Outcomes',
                  id: 'questions',
                  content: <Outcomes {...{ project }} />
                },
                {
                  label: 'Phases',
                  id: 'phases',
                  content: <Phases {...{ project }} />
                }
              ]}
            />
          </RadSpaceBetween>
        }
      />

    )
  }
}

function Organizations ({ project, organizationOptions, navigate }) {
  const [showAddOrgModal, setShowAddOrgModal] = useState(false)
  const [formValues, setFormValues] = useState({})

  const { organizations } = project

  // remove existing organizations from options
  const availableOptions = organizationOptions.filter(x => !organizations.map(y => parseInt(y.organization.id)).includes(parseInt(x.value)))

  function cancelAction () {
    setShowAddOrgModal(false)
    setFormValues({})
  }

  function okAction (organizationId) {
    navigate(`organization/${formValues.organizationId}/add`)
  }

  return (
    <>
      <RadSpaceBetween size='m'>
        <RadBox textAlign='right'>
          <RadButton
            onClick={() => setShowAddOrgModal(true)}
            disabled={availableOptions.length < 1}
            disabledReason='All organizations have been added to this project.'
          >
            Add Organization
          </RadButton>
        </RadBox>
        <RadCards
          ariaLabels={{
            itemSelectionLabel: (e, t) => `select ${t.name}`,
            selectionGroupLabel: 'Item selection'
          }}
          cardDefinition={{
            header: item => (
              <RadHeader actions={
                <RadBox>
                  <RadButton
                    variant='inline-icon'
                    iconName='edit'
                    onClick={() => navigate(`organization/${item.organizationId}/edit`)}
                  />
                  <ItemDeleteButton {...{ item, navigate }} projectId={project.id} />
                </RadBox>
              }
              >
                <RadLink fontSize='heading-xl' href={`/organization/${item.organizationId}`}>
                  {item.organization.name}
                </RadLink>
              </RadHeader>
            ),
            sections: [
              {
                id: item => item.name + item.id,
                content: item => {
                  return (
                    <RadKeyValuePairs
                      columns={2}
                      items={[
                        {
                          label: 'Org Type',
                          value: item.organization.organizationType?.name ?? '-'
                        },
                        {
                          label: 'Org Role(s)',
                          value: item.roleTypes.length > 0 ? item.roleTypes.map(r => r.name).join(', ') : '-'
                        },
                        {
                          label: 'DSA Required',
                          value: item.isDsaRequired ? 'Yes' : 'No'
                        },
                        {
                          label: 'Participants',
                          value: item.people.length > 0
                            ? (
                              <RadSpaceBetween>
                                {
                                  item.people.map(p => p.person).sort((a, b) => { return a.lastName - b.lastName })
                                    .map((person, idx) => (
                                      <RadLink key={idx} href={`/person/${person.id}`}>
                                        {person.name} ({person.email})
                                      </RadLink>
                                    ))
                                }
                              </RadSpaceBetween>
                              )
                            : '-'
                        }
                      ]}
                    />
                  )
                }
              }
            ]
          }}
          cardsPerRow={[
            { cards: 1 }
          ]}
          items={organizations ?? []}
          variant='full-page'
        />
      </RadSpaceBetween>

      <RadModal
        onDismiss={() => cancelAction()}
        visible={showAddOrgModal}
        footer={
          <RadBox float='right'>
            <RadSpaceBetween direction='horizontal' size='xs'>
              <RadButton variant='link' onClick={() => cancelAction()}>Cancel</RadButton>
              <RadButton variant='primary' onClick={() => okAction()} disabled={formValues.organizationId == null}>Ok</RadButton>
            </RadSpaceBetween>
          </RadBox>
        }
        header='Select an organization'
      >
        <RadFormField>
          <RadSelect
            selectedOption={formValues.organizationId ? organizationOptions.find(x => x.value === formValues.organizationId?.toString()) : { value: '', label: '-' }}
            onChange={({ detail }) => {
              if (detail.selectedOption.value !== '') {
                setFormValues({ ...formValues, organizationId: parseInt(detail.selectedOption.value) })
              } else {
                setFormValues({ ...formValues, organizationId: null })
              }
            }}
            options={availableOptions}
            selectedAriaLabel='Selected'
            empty='No matches found'
          />
        </RadFormField>
      </RadModal>
    </>
  )
}

function ItemDeleteButton ({ item, navigate, projectId }) {
  const remove = useDelete(`/api/project/project-organization/${item.id}/delete`, () => { navigate(`/project/${projectId}`) })
  const confirmDelete = useConfirm('Remove organization?', 'Remove organization permanently? This action cannot be undone.', () => { remove() })

  return <RadButton variant='inline-icon' iconName='remove' onClick={() => confirmDelete(item.id)} />
}

function Finance ({ project }) {
  return (
    <RadContainer header={<RadHeader variant='h2'>Finance</RadHeader>}>
      <RadGrid gridDefinition={[
        { colspan: { default: 12, xs: 4 } },
        { colspan: { default: 12, xs: 4 } },
        { colspan: { default: 12, xs: 4 } }
      ]}
      >
        <div>
          <RadBox variant='awsui-key-label'>Total cost</RadBox>
          <div>{project.totalCostFormat ?? '-'}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>Total hours</RadBox>
          <div>{project.totalHours ?? '-'}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>Support Agreement</RadBox>
          <div>{project.hasSupportContract ? 'Yes' : 'No'}</div>
        </div>
      </RadGrid>
    </RadContainer>
  )
}

function Methods ({ project, analysisTypes }) {
  return (
    <RadContainer header={<RadHeader variant='h2'>Methods</RadHeader>}>
      <RadSpaceBetween size='l'>
        <RadGrid gridDefinition={[
          { colspan: { default: 12, xs: 4 } },
          { colspan: { default: 12, xs: 4 } }
        ]}
        >
          <div>
            <RadBox variant='awsui-key-label'>Data Coverage Start Date</RadBox>
            <div>{project.dataCoverageStartDate != null ? formatDate(project.dataCoverageStartDate) : '-'}</div>
          </div>
          <div>
            <RadBox variant='awsui-key-label'>Data Coverage End Date</RadBox>
            <div>{project.dataCoverageEndDate != null ? formatDate(project.dataCoverageEndDate) : '-'}</div>
          </div>
        </RadGrid>
        <div>
          <RadBox variant='awsui-key-label'>Analysis types</RadBox>
          <div>
            {
              analysisTypes.length > 0
                ? (
                  <RadSpaceBetween direction='horizontal' size='xs'>
                    {analysisTypes.sort().map((x, idx) => <RadBadge key={idx}>{x}</RadBadge>)}
                  </RadSpaceBetween>
                  )
                : '-'
            }
          </div>
        </div>
      </RadSpaceBetween>
    </RadContainer>
  )
}

function Notes ({ project }) {
  return (
    <RadContainer header={<RadHeader variant='h2'>Notes</RadHeader>}>
      {project.notes ?? '-'}
    </RadContainer>
  )
}

function Documents ({ project }) {
  return (
    <RadContainer header={<RadHeader variant='h2'>Documents</RadHeader>}>
      <RadContentLayout>
        {
          project.charterLink != null || project.retroNotesLink != null
            ? (
              <ul>
                <RadSpaceBetween size='l'>
                  {
                    project.charterLink != null &&
                      <li>
                        <RadLink href={project.charterLink} target='_blank'>
                          Project Charter
                        </RadLink>
                      </li>
                  }
                  {
                    project.retroNotesLink != null &&
                      <li>
                        <RadLink href={project.retroNotesLink} target='_blank'>
                          Retro Notes
                        </RadLink>
                      </li>
                  }
                </RadSpaceBetween>
              </ul>
              )
            : '-'
        }
      </RadContentLayout>
    </RadContainer>
  )
}

function Outcomes ({ project }) {
  const questions = project.questions.sort((a, b) => a.id - b.id) ?? []
  const [showEditModal, setShowEditModal] = useState(false)
  const [formValues, setFormValues] = useState(questions)

  function cancelAction () {
    setShowEditModal(false)
    setFormValues(questions)
  }

  const save = usePost(
    `/api/project/${project.id}/questions/update`,
    formValues,
    (resp) => {
      setShowEditModal(false)
      setFormValues(resp.sort((a, b) => a.id - b.id).map(pq => ({
        id: pq.id,
        question: pq.question,
        answer: pq.answer
      })))
    }
  )

  const onFieldChanged = (changed, remove = false) => {
    const saved = [
      ...formValues.filter(x => x.id != null && changed.id !== x.id),
      changed.id != null && !remove ? changed : null
    ].filter(x => x != null).sort((a, b) => a.id - b.id)

    const unsaved = [
      ...formValues.filter(x => x.uuid != null && changed.uuid !== x.uuid),
      changed.uuid != null && !remove ? changed : null
    ].filter(x => x != null).sort((a, b) => a.sortOrder - b.sortOrder)

    setFormValues([...saved, ...unsaved])
  }

  return (
    <>
      <RadSpaceBetween size='m'>
        <RadBox textAlign='right'>
          <RadButton onClick={() => setShowEditModal(true)}>
            Edit Outcomes
          </RadButton>
        </RadBox>
        <RadContainer header={<RadHeader variant='h2'>Outcomes</RadHeader>}>
          <RadContentLayout>
            {
              questions.length > 0
                ? (<RadKeyValuePairs
                    columns={1}
                    items={questions.map((pq, i) => ({
                      label: `${i + 1}. ${pq.question}`,
                      value: pq.answer
                    }))}
                   />)
                : '-'
            }
          </RadContentLayout>
        </RadContainer>
      </RadSpaceBetween>

      <RadModal
        size='large'
        onDismiss={() => cancelAction()}
        visible={showEditModal}
        footer={
          <RadBox float='right'>
            <RadSpaceBetween direction='horizontal' size='xs'>
              <RadButton variant='link' onClick={() => cancelAction()}>Cancel</RadButton>
              <RadButton
                variant='primary'
                onClick={() => save()}
              >
                Save
              </RadButton>
            </RadSpaceBetween>
          </RadBox>
        }
        header='Edit Outcomes'
      >
        <RadSpaceBetween size='xxl'>
          {
            formValues.length > 0 &&
              <RadSpaceBetween size='l'>
                {
                  formValues.map(formQuestion => (
                    <RadSpaceBetween size='xs' key={formQuestion.id ?? formQuestion.uuid}>
                      <RadFormField
                        stretch
                        label='Question'
                        field={`${formQuestion.id ?? formQuestion.uuid}.question`}
                        required
                      >
                        <RadGrid gridDefinition={[{ colspan: { default: 10, xs: 11 } }, { colspan: { default: 2, xs: 1 } }]}>
                          <RadInput
                            ariaRequired
                            placeholder='Enter question'
                            value={formQuestion.question}
                            onChange={event => onFieldChanged({ ...formQuestion, question: event.detail.value })}
                          />
                          <RadButton
                            iconName='remove'
                            onClick={() => onFieldChanged(formQuestion, true)}
                          />
                        </RadGrid>
                      </RadFormField>

                      <RadFormField
                        stretch
                        label='Answer'
                        field={`${formQuestion.id ?? formQuestion.uuid}.answer`}
                      >
                        <RadTextarea
                          placeholder='Enter answer'
                          value={formQuestion.answer}
                          onChange={event => onFieldChanged({ ...formQuestion, answer: event.detail.value })}
                        />
                      </RadFormField>
                    </RadSpaceBetween>
                  ))
                }
              </RadSpaceBetween>
          }

          <RadBox textAlign='center'>
            <RadSpaceBetween size='xl'>
              {formValues.length < 1 && 'No questions.'}
              <RadButton
                onClick={() => {
                  onFieldChanged({
                    uuid: uuidv4(),
                    question: '',
                    answer: '',
                    sortOrder: formValues.length + 1
                  })
                }}
              >
                Add question
              </RadButton>
            </RadSpaceBetween>
          </RadBox>
        </RadSpaceBetween>
      </RadModal>
    </>
  )
}

function Phases ({ project }) {
  const phases = project.phases.sort((a, b) => a.id - b.id) ?? []
  const [showEditModal, setShowEditModal] = useState(false)
  const [formValues, setFormValues] = useState(phases)
  const { data: phaseTypeOptions } = useGet('/api/option/projectPhaseType?required=true')

  function cancelAction () {
    setShowEditModal(false)
    setFormValues(phases)
  }

  const save = usePost(
    `/api/project/${project.id}/phases/update`,
    formValues,
    (resp) => {
      setShowEditModal(false)
      setFormValues(resp.sort((a, b) => a.id - b.id).map(phase => ({
        id: phase.id,
        phaseTypeId: phase.phaseTypeId,
        startDate: phase.startDate,
        endDate: phase.endDate,
        totalWeeks: phase.totalWeeks,
        totalHours: phase.totalHours,
        totalCost: phase.totalCost
      })))
    }
  )

  const onFieldChanged = (changed, remove = false) => {
    const saved = [
      ...formValues.filter(x => x.id != null && changed.id !== x.id),
      changed.id != null && !remove ? changed : null
    ].filter(x => x != null).sort((a, b) => a.id - b.id)

    const unsaved = [
      ...formValues.filter(x => x.uuid != null && changed.uuid !== x.uuid),
      changed.uuid != null && !remove ? changed : null
    ].filter(x => x).sort((a, b) => a.sortOrder - b.sortOrder)

    setFormValues([...saved, ...unsaved])
  }

  if (phaseTypeOptions == null) {
    return null
  }

  return (
    <>
      <RadSpaceBetween size='m'>
        <RadBox textAlign='right'>
          <RadButton onClick={() => setShowEditModal(true)}>
            Edit Phases
          </RadButton>
        </RadBox>
        <RadContainer header={<RadHeader variant='h2'>Phases</RadHeader>}>
          <RadContentLayout>
            {
              phases.length > 0
                ? (<RadKeyValuePairs
                    columns={Math.min(3, phases.length)}
                    items={phases.map(p => ({
                      type: 'group',
                      title: p.phaseType.name,
                      items: [
                        { label: 'Start date', value: p.startDate != null ? formatDate(p.startDate) : '-' },
                        { label: 'End date', value: p.endDate != null ? formatDate(p.endDate) : '-' },
                        { label: 'Total weeks', value: p.totalWeeks ?? '-' },
                        { label: 'Total hours', value: p.totalHours ?? '-' },
                        { label: 'Total cost', value: `$${p.totalCost}` ?? '-' }
                      ]
                    }))}
                   />)
                : '-'
            }
          </RadContentLayout>
        </RadContainer>
      </RadSpaceBetween>

      <RadModal
        size='large'
        onDismiss={() => cancelAction()}
        visible={showEditModal}
        footer={
          <RadBox float='right'>
            <RadSpaceBetween direction='horizontal' size='xs'>
              <RadButton variant='link' onClick={() => cancelAction()}>Cancel</RadButton>
              <RadButton
                variant='primary'
                onClick={() => save()}
              >
                Save
              </RadButton>
            </RadSpaceBetween>
          </RadBox>
        }
        header='Edit Phases'
      >
        <RadSpaceBetween size='xxl'>
          {
            formValues.length > 0 &&
              <RadSpaceBetween size='l'>
                {
                  formValues.map(formPhase => (
                    <RadSpaceBetween size='xs' key={formPhase.id ?? formPhase.uuid}>
                      <RadFormField
                        stretch
                        label='Phase Type'
                        field={`${formPhase.id ?? formPhase.uuid}.phaseTypeId`}
                        required
                      >
                        <RadGrid gridDefinition={[{ colspan: { default: 10, xs: 11 } }, { colspan: { default: 2, xs: 1 } }]}>
                          <RadSelect
                            selectedOption={
                              formPhase.phaseTypeId
                                ? phaseTypeOptions.find(x => x.value === formPhase.phaseTypeId?.toString())
                                : { value: '', label: '-' }
                            }
                            onChange={({ detail }) => {
                              onFieldChanged({
                                ...formPhase,
                                phaseTypeId: detail.selectedOption.value !== ''
                                  ? parseInt(detail.selectedOption.value)
                                  : null
                              })
                            }}
                            options={phaseTypeOptions.filter(x => !formValues.map(y => y.phaseTypeId).includes(parseInt(x.value)))}
                            selectedAriaLabel='Selected'
                            empty='No matches found'
                          />
                          <RadButton
                            iconName='remove'
                            onClick={() => onFieldChanged(formPhase, true)}
                          />
                        </RadGrid>
                      </RadFormField>

                      <RadGrid gridDefinition={[
                        { colspan: { default: 12, xs: 4 } },
                        { colspan: { default: 12, xs: 4 } }
                      ]}
                      >
                        <RadFormField
                          stretch
                          label='Start date'
                          field={`${formPhase.id ?? formPhase.uuid}.startDate`}
                        >
                          <RadDatePicker
                            onChange={({ detail }) => onFieldChanged({ ...formPhase, startDate: detail.value })}
                            value={formPhase.startDate ?? ''}
                            openCalendarAriaLabel={selectedDate =>
                              'Choose project phase start date' +
                          (selectedDate
                            ? `, selected date is ${selectedDate}`
                            : '')}
                          />
                        </RadFormField>

                        <RadFormField
                          stretch
                          label='End date'
                          field={`${formPhase.id ?? formPhase.uuid}.endDate`}
                        >
                          <RadDatePicker
                            onChange={({ detail }) => onFieldChanged({ ...formPhase, endDate: detail.value })}
                            value={formPhase.endDate ?? ''}
                            openCalendarAriaLabel={selectedDate =>
                              'Choose project phase end date' +
                          (selectedDate
                            ? `, selected date is ${selectedDate}`
                            : '')}
                          />
                        </RadFormField>
                      </RadGrid>

                      <RadGrid gridDefinition={[
                        { colspan: { default: 12, xs: 4 } },
                        { colspan: { default: 12, xs: 4 } },
                        { colspan: { default: 12, xs: 4 } }
                      ]}
                      >
                        <RadFormField
                          stretch
                          label='Total weeks'
                          field={`${formPhase.id ?? formPhase.uuid}.totalWeeks`}
                        >
                          <RadInput
                            value={formPhase.totalWeeks}
                            onChange={event => onFieldChanged({ ...formPhase, totalWeeks: event.detail.value })}
                          />
                        </RadFormField>

                        <RadFormField
                          stretch
                          label='Total hours'
                          field={`${formPhase.id ?? formPhase.uuid}.totalHours`}
                        >
                          <RadInput
                            value={formPhase.totalHours}
                            onChange={event => onFieldChanged({ ...formPhase, totalHours: event.detail.value })}
                          />
                        </RadFormField>

                        <RadFormField
                          stretch
                          label='Total cost'
                          field={`${formPhase.id ?? formPhase.uuid}.totalCost`}
                        >
                          <RadInput
                            value={formPhase.totalCost}
                            onChange={event => onFieldChanged({ ...formPhase, totalCost: event.detail.value })}
                          />
                        </RadFormField>
                      </RadGrid>
                    </RadSpaceBetween>
                  ))
                }
              </RadSpaceBetween>
          }

          <RadBox textAlign='center'>
            <RadSpaceBetween size='xl'>
              {formValues.length < 1 && <RadBox>No phases.</RadBox>}
              <RadButton
                disabled={
                  phaseTypeOptions
                    .filter(x => !formValues.map(y => y.phaseTypeId).includes(parseInt(x.value)))
                    .length < 2 || formValues.length >= phaseTypeOptions.length - 1
                }
                disabledReason='No more phases to add.'
                onClick={() => {
                  onFieldChanged({
                    uuid: uuidv4(),
                    phaseTypeId: '',
                    startDate: '',
                    endDate: '',
                    totalWeeks: '',
                    totalHours: '',
                    totalCost: '',
                    sortOrder: formValues.length + 1
                  })
                }}
              >
                Add phase
              </RadButton>
            </RadSpaceBetween>
          </RadBox>
        </RadSpaceBetween>
      </RadModal>
    </>
  )
}
