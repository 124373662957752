import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { useGet } from '../hooks/useGet'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBreadcrumbGroup } from '../common/RadBreadcrumbGroup'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadGrid } from '../common/RadGrid'
import { RadDivider } from '../common/RadDivider'
import { RadContainer } from '../common/RadContainer'
import { RadForm } from '../common/RadForm'
import { RadBox } from '../common/RadBox'
import { RadFormField } from '../common/RadFormField'
import { RadSelect, RadMultiSelect } from '../common/RadSelect'
import { RadCheckbox } from '../common/RadCheckbox'
import { RadButton } from '../common/RadButton'

export function ProjectOrganizationCreate () {
  const navigate = useNavigate()
  const { projectId, organizationId } = useParams()
  const defaultValues = { roleTypes: [], isDsaRequired: false, projectId, organizationId, people: [] }
  const [formValues, setFormValues] = useState(defaultValues)
  const [projectOrg, setProjectOrg] = useState(null)

  const { data: project } = useGet(projectId != null ? `/api/project/${projectId}` : '')
  const { data: organization } = useGet(projectId != null ? `/api/organization/${organizationId}` : '')
  const { data: orgRoleTypeOptions } = useGet('/api/option/projectOrganizationRoleType')
  const { data: participantRoleTypeOptions } = useGet('/api/option/projectOrganizationParticipantRoleType?required=true')

  useEffect(() => {
    if (project != null) {
      setProjectOrg(
        project.organizations.find(x => x.organizationId === parseInt(organizationId))
      )
    }
  }, [project, organizationId])

  useEffect(() => {
    if (projectOrg != null) {
      setFormValues({
        roleTypes: projectOrg.roleTypes.map(x => ({ id: x.id, value: x.id.toString() })),
        isDsaRequired: projectOrg.isDsaRequired,
        people: projectOrg.people.map(p => ({
          id: p.id,
          personId: p.personId,
          roleId: p.projectRoleId
        })),
        projectId,
        organizationId
      })
    }
  }, [projectOrg])

  const create = usePost(
    `/api/project/${projectId}/organization/${organizationId}/add`,
    formValues,
    (resp) => { navigate(`/project/${projectId}`) }
  )
  const update = usePut(
    `/api/project/${projectId}/organization/${organizationId}/edit`,
    formValues,
    (resp) => { navigate(`/project/${projectId}`) }
  )

  const loadForm =
    project != null &&
    organization != null &&
    formValues != null &&
    orgRoleTypeOptions != null &&
    participantRoleTypeOptions != null

  if (loadForm) {
    const peopleOptions = organization.people?.map(x => ({ label: x.selectListName, value: x.id }))
    const selectedRoleTypes = orgRoleTypeOptions.filter(x => formValues.roleTypes.map(y => y.id.toString()).includes(x.value))
    return (
      <RadAppLayout
        contentHeader={
          <RadHeader variant='h1'>
            {projectOrg == null ? 'Add' : 'Edit'} {organization.name} {projectOrg == null ? 'to' : 'for'} {project.name}
          </RadHeader>
        }
        breadcrumbs={
          <RadBreadcrumbGroup
            items={[
              { text: 'Home', href: '/' },
              { text: 'Projects', href: '/project' },
              { text: project.name, href: `/project/${project.id}` },
              { text: `${projectOrg == null ? 'Add' : 'Edit'} Organization` }
            ]}
            ariaLabel='Breadcrumbs'
          />
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={() => navigate(`/project/${projectId}`)}>Cancel</RadButton>
                  <RadButton
                    formAction='submit'
                    variant='primary'
                    onClick={() => projectOrg == null ? create() : update()}
                  >
                    Save Changes
                  </RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadContainer>
                  <RadSpaceBetween size='l'>
                    <RadFormField label={`${organization.name}'s roles in the project`} field='typeId'>
                      <RadMultiSelect
                        selectedOptions={selectedRoleTypes}
                        onChange={({ detail }) =>
                          setFormValues({
                            ...formValues,
                            roleTypes: detail.selectedOptions.map(x => ({ id: parseInt(x.value) }))
                          })}
                        options={orgRoleTypeOptions}
                        placeholder='Choose options'
                      />
                    </RadFormField>
                    <RadFormField label='Requires a DSA' field='isDsaRequired'>
                      <RadCheckbox
                        onChange={({ detail }) => setFormValues({ ...formValues, isDsaRequired: detail.checked })}
                        checked={formValues.isDsaRequired}
                      >
                        Yes
                      </RadCheckbox>
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>
                {(peopleOptions != null && peopleOptions.length > 0) && <People {...{ formValues, setFormValues, peopleOptions, participantRoleTypeOptions }} />}
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }

  // NOTE: not going to allow edit of organization on an existing product-organization connection
  // separating create and edit forms
}

function People ({ formValues, setFormValues, peopleOptions, participantRoleTypeOptions }) {
  return (
    <RadContainer
      header={
        <RadHeader counter={'(' + formValues.people.length + ')'} variant='h2'>Participants</RadHeader>
      }
    >
      <RadSpaceBetween size='l'>
        {formValues.people.length > 0 &&
          <RadSpaceBetween size='xxs'>
            {formValues.people.map((item) =>
              <PersonEditor
                key={`person-${item.id ?? item.uuid}`}
                {...{ item, formValues, setFormValues, peopleOptions, participantRoleTypeOptions }}
              />
            )}
          </RadSpaceBetween>}
        {formValues.people.length === 0 &&
          <RadBox color='text-status-inactive'>No participants added to project.</RadBox>}
        <RadButton
          onClick={() => {
            const people = formValues.people
            people.push({ uuid: uuidv4(), inactive: false, sortOrder: people.length + 1 })
            setFormValues({ ...formValues, people })
          }}
        >
          Add participant
        </RadButton>
      </RadSpaceBetween>
    </RadContainer>
  )
}

function PersonEditor ({ item, formValues, setFormValues, peopleOptions, participantRoleTypeOptions }) {
  const { people } = formValues
  // TODO: filtering on uuid?
  const filteredOptions = peopleOptions.filter(x => !formValues.people.map(y => y.personId).includes(x.value))

  return (
    <RadSpaceBetween size='s'>
      {item !== formValues.people[0] && <RadBox padding={{ top: 's' }}><RadDivider /></RadBox>}
      <RadGrid
        gridDefinition={[
          { colspan: { default: 12, xs: 5 } },
          { colspan: { default: 12, xs: 3 } },
          { colspan: { default: 12, xs: 4 } }
        ]}
      >
        <RadFormField
          label='Person'
          field={`people.${item.id ?? item.uuid}.personId`}
          required
          stretch
        >
          <RadSelect
            filteringType='auto'
            selectedOption={peopleOptions.find(x => x.value === item.personId)}
            onChange={({ detail }) => {
              item.personId = parseInt(detail.selectedOption.value)
              setFormValues({ ...formValues, people })
            }}
            options={filteredOptions}
            enteredTextLabel={value => value}
            selectedAriaLabel='Selected'
            placeholder='Choose person'
            empty='No matches found'
          />
        </RadFormField>
        <RadFormField
          label='Project role'
          field={`people.${item.id ?? item.uuid}.roleId`}
          stretch
        >
          <RadSelect
            selectedOption={participantRoleTypeOptions.find(x => parseInt(x.value) === item.roleId)}
            onChange={({ detail }) => {
              item.roleId = parseInt(detail.selectedOption.value)
              setFormValues({ ...formValues, people })
            }}
            options={participantRoleTypeOptions}
            enteredTextLabel={value => value}
            selectedAriaLabel='Selected'
            placeholder='Choose role'
            empty='No matches found'
          />
        </RadFormField>
        <RadBox float='right' padding={{ top: 'l' }}>
          <RadButton
            wrapText={false}
            // disabled={people.length === 1}
            onClick={() => {
              const people = formValues.people.filter((x) => x.id !== item.id || x.uuid !== item.uuid)
              setFormValues({ ...formValues, people })
            }}
          >
            Remove participant
          </RadButton>
        </RadBox>
      </RadGrid>
    </RadSpaceBetween>
  )
}
